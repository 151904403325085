import React from "react";
import './aboutus.css'
import img1 from '../../assest/Mask group.png'
import img2 from '../../assest/choose-2.jpg.png'
import roll from '../../assest/roll.gif'
export function Abouttt(){
    return(
        <>
            {/* <div className="container">
                <div className="row">

                    <div  className="col-lg-6 p-5">
                        <div >
                            <h3 className="title pt-5">About Us</h3>
                            <p className="text-white">Our tours and travels division specializes in crafting unforgettable journeys that cater to the discerning traveler.</p>
                            <h4 className="text-white">We offer various services</h4>
                            <p className="text-white">Including luxury hotel bookings, private car rentals, and bespoke travel packages that ensure an unparalleled travel experience. Our international and domestic travel expertise allows us to provide tailored solutions for any destination, making your trip truly extraordinary.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 p-5">
                    <div class="container client-section">
                   
                        <div class="row">
                            <div class="col-md-6 mb-4 mb-md-0 pt-lg-5">
                                <img src={img1} alt="Person with Camera "/>
                            </div>

                            <div class="col-md-6">
                                <img src={img2} alt="Person with Backpack"/>
                                <div class="client-count" >

                                    <img src={roll} alt="Icon"/>
                                    <span>100+ Happy Clients</span>
                                    <div id="services"></div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    </div>
                </div>
            </div> */}
            <div class="container py-5">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <img src={img1} alt="About Us Image" class="img-fluid rounded"/>
            </div>
            <div class="col-lg-6 text-white">
                <h2 class="mb-4 text-warning">About Us</h2>
                <p>At Zion Tours and Travels, we are passionate about helping people explore the world. Founded by friends who shared a love for travel, our company was born from the desire to help others experience the joy and wonder that travelling brings. After years in the corporate world, we decided to follow our passion and create a business that makes travel accessible and enjoyable for everyone.</p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-6">
                <div class="p-4 bg-dark text-white rounded">
                    <h3 class="text-warning">Mission</h3>
                    <p>Our mission is to make travel accessible and enjoyable for everyone. We aim to provide exceptional service and unforgettable travel experiences, ensuring that every journey is as enriching and exciting as possible.</p>
                </div>
            </div>
            <div class="col-md-6 mt-4 mt-md-0">
                <div class="p-4 bg-dark text-white rounded">
                    <h3 class="text-warning">Vision</h3>
                    <p>We envision a world where travel is not just a luxury, but an opportunity for everyone to explore, learn, and grow. We strive to lead in sustainable and responsible travel, supporting local communities and preserving the beauty of our planet.</p>
                </div>
            </div>
        </div>
    </div>
        </>
    );
}