import React from 'react';
import './FloatingImage.css'; // External CSS (optional)
import floating from './assest/Animation - 1724927417268.gif'
const FloatingImage = () => {
  return (
    <a href='tel:8220351620'>
    <img
      src={floating}
      alt="Floating"
      className="floating-image"
    />
    </a>
  );
};

export default FloatingImage;
