import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Menu } from './Components/company/menu/menu';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import { Banner } from './Components/company/banner/banner';
import { Menutt } from './Components/toursandtravells/Menu/menutt';
import { Bannertt } from './Components/toursandtravells/banner/banner';
import { Abouttt } from './Components/toursandtravells/aboutus/aboutus';
import { Servicestt } from './Components/toursandtravells/services/services';
import { Countrydetails } from './Components/toursandtravells/country details/countrydetails';
import { Gallery } from './Components/toursandtravells/gallery/gallery';
import { Contactform } from './Components/toursandtravells/contactform/contactform';
import { Footer } from './Components/toursandtravells/footer/footer';
import FloatingImage from './Components/FloatingImage';
import { About } from './Components/company/aboutus/aboutus';
import { Mission } from './Components/company/mission/mission';
import Swipingcard from './Components/company/services/services';
import ScrollToTop from './ScrollToTop';
import { Team } from './Components/company/team/team';
import { Achiviment } from './Components/toursandtravells/achivement/achiviment';
import { Menur } from './Components/realestate/Menu/menur';
import { Bannerr } from './Components/realestate/banner/banner';
import { Aboutr } from './Components/realestate/aboutus/aboutus';
import { Servicesr } from './Components/realestate/services/services';
import { Galleryr } from './Components/realestate/gallery/gallery';
function App() {
  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route path='/' element={[<Menu/>,<Banner/>,<About/>,<Mission/>,<Swipingcard/>,<Contactform/>,<Team/>,<Footer/>,<FloatingImage/>]}/>
        <Route path='/tours&travels' element={[<Menutt/>,<Bannertt/>,<Abouttt/>,<Achiviment/>,<Servicestt/>,<Contactform/>,<Footer/>,<FloatingImage/>]}/>
        <Route path='/realstate' element={[<Menur/>,<Bannerr/>,<Aboutr/>,<Servicesr/>,<Galleryr/>,<Footer/>,<FloatingImage/>]}/>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
