import React from "react";
import services1 from '../../assest/Background (1).png'
import services2 from '../../assest/Background (2).png'
import services3 from '../../assest/Background (3).png'
import services4 from '../../assest/Background (4).png'

import team from '../../assest/team.png'
import value from '../../assest/value.png'
 
import './services.css'
export function Servicesr(){
    return(
        < >
        <div className="container mx-auto ">
              <div class="row mt-4">
                <div class="col-lg-6 col-12  bg-white">
                    <div class="p-3   rounded">
                        <h5 class="text-warning">Mission</h5>
                        <p className="text-black font ">Our mission is to empower our clients, professionals, and partners by building the most stable solutions of the future. We strive to unlock full potential of collective investments, providing innovative and accessible opportunities that redefine traditional real estate transactions.</p>
                    </div>
                </div>
                <div class="col-lg-5 col-12  ms-lg-5 bg-white" id="services">
                    <div class="py-3   rounded">
                        <h5 class="text-warning">Vision</h5>
                        <p className="text-black mt-3 font">To lead the evolution of real estate investments by creating a platform that seamlessly integrates the power of collective funding with cutting-edge technology, delivering unmatched value and opportunities to our investor community.</p>
                    </div>
                </div>
              </div>
        </div>
        <div class="container py-5">
        <h2 class="text-center text-warning mb-5">Our Services</h2>
        <div class="row">
            <div class="col-lg-6 mb-4">
                <div class="card h-100 border-0 shadow">
                    <img src={services1} class="card-img-top" alt="Commercial Buildings"/>
                    <div class="card-body">
                        <h5 class="card-title text-warning">Commercial Buildings</h5>
                        <p class="card-text">Premium commercial properties: high-rise offices, malls, and specialized facilities.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mb-4">
                <div class="card h-100 border-0 shadow">
                    <img src={services2} class="card-img-top" alt="Residential Buildings"/>
                    <div class="card-body">
                        <h5 class="card-title text-warning">Residential Buildings</h5>
                        <p class="card-text">Luxury homes, villas, and apartments: prime locations, quality, and convenience.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mb-4">
                <div class="card h-100 border-0 shadow">
                    <img src={services3} class="card-img-top" alt="Land"/>
                    <div class="card-body">
                        <h5 class="card-title text-warning">Land</h5>
                        <p class="card-text">Prime development land and farming estates: ideal for investment and growth.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mb-4">
                <div class="card h-100 border-0 shadow">
                    <img src={services4} class="card-img-top" alt="Farming Land"/>
                    <div class="card-body">
                        <h5 class="card-title text-warning">Farming Land</h5>
                        <p class="card-text">High-quality agricultural land: sustainable, efficient, and ideal for modern farming.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </>
    );
}