import React from "react";
import './aboutus.css'
import img1 from '../../assest/thumb-slider-img-1.jpg.png'
import img2 from '../../assest/choose-2.jpg.png'
import roll from '../../assest/roll.gif'

export function Aboutr(){
    return(
        <>
            
            <div class="container py-5">
                <div class="row align-items-center">
                    <div class="col-lg-6 mb-4 mb-lg-0">
                        <img src={img1} alt="About Us Image" class="img-fluid rounded"/>
                    </div>
                    <div class="col-lg-6 text-white">
                        <h2 class="mb-4 text-warning">About Us</h2>
                        <p className="ms-lg-3 font">Introducing Zion Group, a pioneering platform dedicated to transforming the landscape of real estate investments through collective participation. At Zion, we believe in the power of democratizing real estate, enabling individuals to pool resources and capitalize on lucrative properties across Tamil Nadu. Our commitment to transparency and value-driven service ensures that every participant benefits from a straightforward and rewarding investment experience.</p>
                        {/* <div class="row mt-4">
                            <div class="col-md-6 mb-3 bg-white">
                                <div class="p-3   rounded">
                                    <h5 class="text-warning">Mission</h5>
                                    <p className="text-black">Our mission is to empower our clients, professionals, and partners by building the most stable solutions of the future. We strive to unlock full potential of collective investments, providing innovative and accessible opportunities that redefine traditional real estate transactions.</p>
                                </div>
                            </div>
                            <div class="col-md-6 mt-4 mt-md-0 bg-white">
                                <div class="p-3   rounded">
                                    <h5 class="text-warning">Vision</h5>
                                    <p className="text-black">To lead the evolution of real estate investments by creating a platform that seamlessly integrates the power of collective funding with cutting-edge technology, delivering unmatched value and opportunities to our investor community.</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    );
}