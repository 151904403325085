import React,{useRef,useEffect} from 'react';
import './banner.css'
// import * as THREE from 'three';
import AOS from "aos";
import "aos/dist/aos.css";
import NET from 'vanta/dist/vanta.net.min';
import { Link } from 'react-router-dom';
import banner1 from '../../assest/Background.png'
import banner2 from '../../assest/Frame 1.png'
import banner3 from '../../assest/Frame 3.png'
import mobile1 from '../../assest/bannermobile.png'
import mobile2 from '../../assest/bannermobile2.png'
import mobile3 from '../../assest/bannermobile3.png'
export function Bannertt(){
//    const vantaRef = useRef(null);
    // useEffect(() => {
    //     AOS.init();
    //     AOS.refresh();
    //     const vantaEffect = NET({
    //         el: vantaRef.current,
    //         backgroundColor: 'black',
    //         color:'#ffc53b',
    //         mouseControls: true,
    //         touchControls: true,
    //         gyroControls: false,
    //         minHeight: 200.00,
    //         minWidth: 200.00,
    //         scale: 1.00,
    //         scaleMobile: 1.00,
            
    //       });
      
    //       return () => {
    //         if (vantaEffect) vantaEffect.destroy();
    //       };
    //   }, []);
    return(
       <>
            {/* <div className='container-fluid backanni'  ref={vantaRef}>
                <h1 className='p-5 text-white fontstyle'  data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">Explore the World with Us</h1>
            </div> */}
            <div id="carouselExampleCaptions" className="carousel slide h-75 container" data-bs-ride="carousel" >
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions"   data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    
                </div>
                <div className="carousel-inner" >
                    <div className="carousel-item active" data-bs-interval="10000"  >
                        <img src={banner1} className="d-md-block d-none w-100 textback" alt="IT Services"   />
                        <img src={mobile1} className="d-block d-md-none w-100 textback" alt="IT Services"   />
                        {/* <div className="carousel-caption">
                            <h1 className="d-block d-md-block" data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="800">Explore the World with Us</h1>
                            <h5 className="d-none d-lg-block text-center">Discover the beauty of the world with our tailored travel packages. Whether you're dreaming of a serene getaway or an adventurous journey, we bring your travel aspirations to life.</h5>
                            <h5 className="d-none d-md-block">An initiative supported by KGiSL to foster the next wave of inspiring business achievements.</h5>
                            <button className="btn btn-primary ms-auto">Explore more</button>
                        </div> */}
                    </div>
                    
                    <div className="carousel-item" data-bs-interval="10000" >
                        <img src={banner2} className="d-md-block d-none w-100 textback" alt="IT Services"   />
                        <img src={mobile2} className="d-block d-md-none w-100 textback" alt="IT Services"   />
                    </div>
                    <div className="carousel-item" data-bs-interval="10000" >
                        <img src={banner3} className="d-md-block d-none w-100 textback" alt="IT Services"   />
                        <img src={mobile3} className="d-block d-md-none w-100 textback" alt="IT Services"   />
                    </div>
                    


                </div>
                <div id="aboutpage"></div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
       </> 
    );
}

