import React,{useRef,useEffect} from 'react';
import './banner.css'
// import * as THREE from 'three';
import AOS from "aos";
import "aos/dist/aos.css";
import NET from 'vanta/dist/vanta.net.min';
import { Link } from 'react-router-dom';
export function Banner(){
   const vantaRef = useRef(null);
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        const vantaEffect = NET({
            el: vantaRef.current,
            backgroundColor: 'black',
            color:'#ffc53b',
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            scale: 1.00,
            scaleMobile: 1.00,
            
          });
      
          return () => {
            if (vantaEffect) vantaEffect.destroy();
          };
      }, []);
    return(
       <>
            {/* <div className='container-fluid backanni'  ref={vantaRef}>
                <h1 className='p-5 text-white fontstyle'  data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">Explore the World with Us</h1>
            </div> */}
            <div id="carouselExampleCaptions" className="carousel slide h-75 d-none d-lg-block" data-bs-ride="carousel" >
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    
                </div>
                <div className="carousel-inner" ref={vantaRef}>
                    <div className="carousel-item active" data-bs-interval="10000"  >
                        {/* <img src={kggl} className="d-block w-100 textback" alt="IT Services"   /> */}
                        <div className="carousel-caption">
                            <h1 className="d-block d-md-block" data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="800">Explore the World with Us</h1>
                            <h5 className="d-none d-lg-block text-center">Discover the beauty of the world with our tailored travel packages. Whether you're dreaming of a serene getaway or an adventurous journey, we bring your travel aspirations to life.</h5>
                            {/* <h5 className="d-none d-md-block">An initiative supported by KGiSL to foster the next wave of inspiring business achievements.</h5> */}
                            {/* <button className="btn btn-primary ms-auto">Explore more</button> */}
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="10000" >
                        {/* <img src={healthcare} className="d-block w-100" alt="Global Healthcare" /> */}
                        <div className="carousel-caption float-end">
                            <div className="float-end me-4">
                                <h1 className="d-block d-md-block" data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="800">Your Trusted Real Estate Partner </h1>
                                <h5 className='d-none d-lg-block text-center'>From commercial properties to dream homes, we offer comprehensive real estate solutions that cater to your needs. 
Invest in your future with confidence.
</h5><br />
                                {/* <Link to='/healthcare'><button className="btn btn-primary">Explore more</button></Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="10000" ref={vantaRef}>
                        {/* <img src={digitalmarketing} className="d-block w-100" alt="Digital Marketing" /> */}
                        <div className="carousel-caption d-block d-md-block">
                        <h1 className="d-block d-md-block" data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="800"> Nurturing Agriculture & Health </h1>
                                <h5 className='d-none d-lg-block text-center'>Empowering communities through sustainable agriculture and health solutions. From cattle farming to medical pharmacies, we’re dedicated to improving lives.</h5><br />
                            {/* <Link to='/digital marketing'><button className="btn btn-primary ms-auto">Explore more</button></Link> */}
                        </div>
                        
                    </div>
                    

                    
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <div id='aboutpage'></div>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
       </> 
    );
}

