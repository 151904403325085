import React,{useEffect} from "react";
import './aboutus.css'
import img1 from '../../assest/choose-1.jpg.png'
import img2 from '../../assest/choose-2.jpg.png'
import roll from '../../assest/roll.gif'
import imageabout from '../../assest/FAINAL 2.png'
import AOS from "aos";
import "aos/dist/aos.css";
export function About(){
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        
      }, []);
    return(
        <>
            <div className="container-fluid">
                <div className="row">

                    <div className="col-lg-6 p-5">

                    
                        <img src={imageabout} className="container-fluid" data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="1000"/>
                    </div>
                    <div  className="col-lg-6 p-5">
                        <div >
                            <h3 className="title pt-5">About Us</h3>
                            <p className="text-white font">Our company is a dynamic and diverse organization, committed to delivering excellence across a wide range of sectors. With expertise in tours and travels, real estate, agriculture, cattle farming, and medical pharmacy, we are dedicated to meeting the unique needs of our clients. Our mission is to enhance your lifestyle by offering personalized, high-quality services tailored to your specific requirements. Whether you're planning a dream vacation, searching for your perfect home, seeking sustainable agricultural solutions, or ensuring access to quality healthcare products, we put our customers at the heart of everything we do. Through innovation, integrity, and a focus on sustainability, we strive to make a positive impact on both individual lives and the broader community.</p>
                           
                            
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
}