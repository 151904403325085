import React from "react";
import contactform from '../../assest/Section.png'
export function Contactform(){
    return(
        <>
            <div className="container my-5">
                <img src={contactform} className="col-12"/>
            </div>
        </>
    );
}