import React from "react";

import './gallery.css'
import gallery1 from '../../assest/Link (17).png'
import gallery2 from '../../assest/Link (18).png'
import gallery3 from '../../assest/Link (19).png'
import gallery4 from '../../assest/gallery-img-4.jpg.png'
import gallery5 from '../../assest/Link (20).png'

export function Galleryr(){
    return(
        <>
         <div class="container py-5" id="gallery">
        <h2 class="text-center text-warning mb-5">Gallery</h2>
        <div class="row g-4">
            <div class="col-md-4">
                <img src={gallery1} alt="Gallery Image 1" class="img-fluid rounded shadow mb-4"/>
                <img src={gallery2} alt="Gallery Image 1" class="img-fluid rounded shadow"/>
            </div>
            <div class="col-md-4">
                <img src={gallery3} alt="Gallery Image 1" class="img-fluid rounded shadow"/>
            </div>
            <div class="col-md-4">
                <img src={gallery4}  alt="Gallery Image 2" class="img-fluid rounded shadow mb-4"/>
                <img src={gallery5}  alt="Gallery Image 2" class="img-fluid rounded shadow"/>
            </div>
            
        </div>
    </div>

        </>
    );
}