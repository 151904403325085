import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "./servicer.css";
import card1 from '../../assest/Group 36.png'
import card2 from '../../assest/Group 37.png'
import card3 from '../../assest/Group 35.png'
import card4 from '../../assest/Group 33.png'
import card5 from '../../assest/Group 34.png'
import { Link } from 'react-router-dom';
function Swipingcard() {
  return (
    <div className='container-fluid  pb-5 p-5' id='servicesitpage'>
        <h3 className="title py-5 text-center" >Services</h3>
    <div className='container-fluid'>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={30}
        slidesPerView={3}
        navigation
        pagination={{ clickable: true }}
        breakpoints={{
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          600: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        }}
      >
        
        <SwiperSlide>
          <div className='ms-5 ps-lg-5'>
          <Link to="/tours&travels">
            <img src={card1} className='col-10'/>
          
        </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div  className='ms-5 ps-lg-5'>
          <Link to="/realstate">
          
              <img src={card2} className='col-10' />
          </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='ms-5 ps-lg-5' >
            <img src={card3} className='col-10'/>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='ms-5 ps-lg-5'>
          <img src={card4} className='col-10'/>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div  className='ms-5 ps-lg-5'>
          <img src={card5} className='col-10'/>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
    </div>
  )
}

export default Swipingcard