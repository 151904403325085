import React from "react";
import services1 from '../../assest/Link (10).png'
import services2 from '../../assest/Link (21).png'
import services3 from '../../assest/Link (12).png'
import services4 from '../../assest/Link (13).png'
import services5 from '../../assest/Link (14).png'
import services6 from '../../assest/Link (22).png'
import services7 from '../../assest/Link (16).png'
import team from '../../assest/team.png'
import value from '../../assest/value.png'
 
import './services.css'
export function Servicestt(){
    return(
        < >
            <div class="container py-5" id="services">
                <div class="row">
                    <div class="col-lg-6 mb-4 mb-lg-0">
                        <div class="p-4 bg-white text-dark rounded shadow">
                            <div class="d-flex align-items-center mb-3">
                                <img src={team} alt="Our Team" class="img-fluid me-3 maxw" />
                                <h4 class="text-warning mb-0">Our Team</h4>
                            </div>
                            <p className="pt-3">Our team consists of passionate travelers with a wealth of knowledge and experience in the travel industry. From expert travel advisors to a friendly customer service team, every member is committed to making your travel experience truly unforgettable.</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="p-4 bg-white text-dark rounded shadow">
                            <div class="d-flex align-items-center mb-3">
                                <img src={value} alt="Our Values" class="img-fluid me-3 maxw" />
                                <h4 class="text-warning mb-0">Our Values</h4>
                            </div>
                            <p>At Zion Tours and Travels, we believe in sustainable and responsible travel. We are dedicated to minimizing our environmental impact and supporting local communities. Our commitment to exceptional service ensures that every step of your journey.</p>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="title pt-5 text-center" >Services</h3>
            <div class="container mt-4">
            
                <div class="row">
                    <div class="col-md-4">
                        <div class="service-card">
                            <img src={services1} alt="Hotel Booking"/>
                            {/* <div class="overlay">
                                <h5>Hotel Booking</h5>
                                <p>Global luxury hotel booking service with tailored, exclusive accommodation options.</p>
                            </div> */}
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="service-card ">
                            <img src={services2}  alt="Car Hiring"/>
                            {/* <div class="overlay">
                                <h5>Car Hiring</h5>
                                <p>Flexible car hire with luxury options and dependable, well-maintained vehicles.</p>
                            </div> */}
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="service-card">
                            <img src={services3} alt="Air Ticket"/>
                            {/* <div class="overlay">
                                <h5>International / Domestic Air Ticket</h5>
                                <p>Expert air ticketing with exclusive deals, first-class options, and support.</p>
                            </div> */}
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="service-card">
                            <img src={services4} alt="Passport and Visa System"/>
                            {/* <div class="overlay">
                                <h5>Passport and Visa System</h5>
                                <p>Streamlined passport and visa service with expert, efficient, and timely processing.</p>
                            </div> */}
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="service-card">
                            <img src={services5} alt="Honeymoon and Tour Packages"/>
                            {/* <div class="overlay">
                                <h5>Honeymoon and Tour Packages</h5>
                                <p>Custom honeymoon packages for unforgettable, personalized, luxurious, and seamless experiences.</p>
                            </div> */}
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="service-card">
                            <img src={services6} alt="Pryde Mountains"/>
                        
                            {/* <div cla8ss="overlay">
                                <h5>Pryde Mountains</h5>
                                <p>Prydelands, 100 Trips</p>
                            </div> */}
                            
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="service-card">
                            <img src={services7} alt="Eiffel Tower"/>
                            {/* <div class="overlay">
                                <h5>Eiffel Tower</h5>
                                <p>Paris, 24 Trips</p>
                            </div> */}
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );
}