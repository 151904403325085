import React from "react";
import prakash from '../../assest/Frame 14.png'
import abdul from '../../assest/Frame 19.png'
import aruna from '../../assest/Frame 18.png'
import gokul from '../../assest/Frame 20.png'
import kavin from '../../assest/Frame 21.png'

import './team.css'

export function Team(){
    return(
        <>
            <div class="container-fluid bg-black bgimageteam  text-white py-5">
        <div class="text-center mb-5">
            <h2 class="text-warning">Our Team</h2>
            <p class="text-warning">Teamwork Is The Cornerstone Of Success In Any Collaborative Effort</p>
        </div>
        <div class="row justify-content-center text-center">
        <div class="col-md-2 col-sm-6 mb-4">
                <div class="team-member p-3">
                    <img src={aruna} alt="Aruna Mahalingam" class="img-fluid rounded"/>
                    <h5 class="mt-3 text-warning">Aruna Mahalingam</h5>
                    <p class="text-white">Proprietor</p>
                </div>
            </div>
            <div class="col-md-2 col-sm-6 mb-4">
                <div class="team-member p-3">
                    <img src={prakash} alt="Prakash Karunanidhi" class="img-fluid rounded"/>
                    <h5 class="mt-3 text-warning">Prakash Karunanidhi</h5>
                    <p class="text-white">Chairman</p>
                </div>
            </div>
            <div class="col-md-2 col-sm-6 mb-4">
                <div class="team-member p-3">
                    <img src={abdul} alt="Abdul Khadir" class="img-fluid rounded"/>
                    <h5 class="mt-3 text-warning">Abdul Khadir</h5>
                    <p class="text-white">Managing Director</p>
                </div>
            </div>
            <div class="col-md-2 col-sm-6 mb-4">
                <div class="team-member p-3">
                    <img src={gokul} alt="Abdul Khadir" class="img-fluid rounded"/>
                    <h5 class="mt-3 text-warning">Gokul</h5>
                    <p class="text-white">General Manager</p>
                    <p class="text-white">Tours And Travels</p>
                </div>
            </div>
            <div class="col-md-2 col-sm-6 mb-4">
                <div class="team-member p-3">
                    <img src={kavin} alt="Abdul Khadir" class="img-fluid rounded"/>
                    <h5 class="mt-3 text-warning">Kevin Ruther . G</h5>
                    <p class="text-white">General Manager</p>
                    <p class="text-white">Real Estate</p>
                </div>
            </div>
           
        </div>
    </div>
        </>
    );
}