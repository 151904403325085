import React from "react";
import './mission.css'
import mission from '../../assest/Group 40.png'

export function Mission(){
    return(
        <>
             <div className="container-fluid back">
                <img src={mission} className="container mx-auto"/>
             </div>
             <div id="services"></div>
        </>
    );
}