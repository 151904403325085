import React from "react";
 import './footer.css'
import footer  from '../../assest/Footer (2).png'
import logo from '../../assest/FAINAL 1.png'
 export function Footer(){
    return(
        <>
            <footer class="footer-section py-5" id="footer">
        <div class="container">
            <div class="row text-white text-center text-md-start">
               
                <div class="col-md-4 mb-4 sideboder">
                    <img src={logo} alt="Logo" class="mb-3"/>
                    <p>Call Us</p>
                    <p>8220351620 / 7397729927</p>
                    <p>Mail Us</p>
                    <a href="mailto:toursandtravels@zian-group.com" class="text-white">toursandtravels@zian-group.com</a>
                </div>
                
              
                <div class="col-md-4 my-4 sideboder text-center">
                    <h5 class="mb-4">Quick Views</h5>
                    <ul class="list-unstyled ">
                        <li><a href="#" class="text-white">Home</a></li>
                        <li><a href="#" class="text-white">About Us</a></li>
                        <li><a href="#" class="text-white">Services</a></li>
                        <li><a href="#" class="text-white">Contact</a></li>
                    </ul>
                </div>
                
                
                <div class="col-md-4 my-4 text-center">
                    <h5 class="mb-4">Contact Info</h5>
                    <p>13 Ashok Nagar</p>
                    <p>3rd street </p>
                    <p>kavundampalayam</p>
                    <p>coimbatore 641025</p>
                </div>
            </div>
        </div>
        <div class="container-fluid bg-dark text-center p-3">
            <p class=" text-white">&copy; 2024 Zian Groups. Developed by IT OXFORD</p>
        </div>
    </footer>

        </>
    );
 }