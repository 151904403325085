import React from "react";
import './achiviment.css'
import icon1 from '../../assest/icon1.png'
import icon2 from '../../assest/icon2.png'
import icon3 from '../../assest/icon3.png'
export function Achiviment(){
    return(
        <>
            <div class="container-fluid body text-dark py-5">
        <div class="row text-center">
            <div class="col-lg-4 mb-4 mb-lg-0">
                <div class="achievement-icon mb-3">
                    <img src={icon1} alt="Icon 1" class="img-fluid"/>
                </div>
                <p class="lead col-lg-6 mx-auto">Successfully Completed Our 8th Year In The Transport Industry</p>
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
                <div class="achievement-icon mb-3">
                    <img src={icon2} alt="Icon 2" class="img-fluid"/>
                </div>
                <p class="lead col-lg-6 mx-auto">Named The Best Travel Company For Four Consecutive Years</p>
            </div>
            <div class="col-lg-4">
                <div class="achievement-icon mb-3">
                    <img src={icon3} alt="Icon 3" class="img-fluid"/>
                </div>
                <p class="lead col-lg-6 mx-auto">Thrilled To Have Helped Thousands Of Travellers Create Unforgettable Memories</p>
            </div>
        </div>
    </div>

        </>
    );
}